import React from "react"
// import MysticsPage from "@components/Mystics/";
import "@styles/styles.css";
import "@styles/utils.css";

import loadable from '@loadable/component'
const MysticsPage = loadable(() => import('../components/Mystics'))

export default function Mystics() {
  return <MysticsPage/>
}
